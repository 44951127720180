import axios from "axios";

const $host = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SERVER_API_URL || 'http://localhost:9000/'
})

const $authHost = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SERVER_API_URL || 'http://localhost:9000/'
})

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

$authHost.interceptors.response.use(config => config, async error => {
    const originalRequest = error.config
    if (error.response.status === 401) {
        const {data} = await $host.get('api/auth/refresh')
        localStorage.setItem('token', data.accessToken)
        return $authHost.request(originalRequest)
    }
    return error.response
})

export {
    $host,
    $authHost
}
