import defaultSettings from '../default-config.json'

const infoDataSVG = 'data:image/svg+xml;base64,'
const loadedImg = {}

function blobToBase64(blob) {
    const a = new FileReader();
    a.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
        try {
            a.onload = function(e) {
                const base64 = e.target.result.replace(infoDataSVG,'')
                resolve(base64)
            }
        } catch(e) {
            reject(e)
        }
    })
}

export default async function changeColorsSVG({url, name, type = 'skin', skins = {}, parts = {}}) {
    try {
        loadedImg[name] = loadedImg[name] || await fetch(url)
            .then(r => r.blob())
            .then(blob => blobToBase64(blob))
            .catch(e => console.log(e))
        const svgHtml = atob(loadedImg[name])
        let changedSVG = svgHtml

        const swap = (type === 'skin') ? skins : parts
        if (type === 'beard') {
            const defaultColor = defaultSettings.defaultSkin.man
            const regex = new RegExp(`(${defaultColor[0]}|${defaultColor[1]}|${defaultColor[2]}|${defaultColor[3]})`, 'gi')
            changedSVG = changedSVG.replace(regex, defaultSettings.available_skin_color[skins.man])
        }

        Object.keys(swap).map(sex => {
            const defaultColor = (type === 'skin') ? defaultSettings.defaultSkin[sex] : defaultSettings.defaultFacePart[sex]
            const replaceColor = (type === 'skin') ?
                defaultSettings.available_skin_color[swap[sex]] :
                defaultSettings[`available_${type}_color`][swap[sex][type]]

            const regex = new RegExp(`(${defaultColor[0]}|${defaultColor[1]}|${defaultColor[2]}|${defaultColor[3]})`, 'gi')
            changedSVG = changedSVG.replace(regex, replaceColor)
        })
        //Change Black color for print
        // const regex = new RegExp(`(#000000|#000|#000000ff|rgb(0,0,0)|black)`, 'gi')
        // changedSVG = changedSVG.replace(regex, '#000000')
        return infoDataSVG + btoa(changedSVG)
    } catch(e) {
        return url
    }
  }