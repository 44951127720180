import React from 'react'
import './ReviewBlock.scss'

function ReviewBlock() {
  return (
    <div className='reviewBlock'>
        <div className='innerWidth paddingPage'>
          <h2>Reviews</h2>
          <p className="desc">
            Clients say
          </p>
        </div>
    </div>
  )
}

export default ReviewBlock
