// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Nyght Serif";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf");
  font-weight: normal;
  font-style: normal;
}
.orderInfo {
  display: flex;
  flex-direction: column;
  border: 1px solid #303030;
  margin-right: 10px;
}
.orderInfo .line {
  display: flex;
  border-bottom: 1px solid #303030;
  padding: 5px 0;
}
.orderInfo .line > div:first-child {
  font-weight: bold;
  border-right: 1px solid #303030;
  padding: 0 5px;
}
.orderInfo .line:nth-child(2n) {
  background-color: #e1e1e1;
}
.orderInfo .line:last-child {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/constants.scss","webpack://./src/components/OrderInfo/OrderInfo.scss"],"names":[],"mappings":"AAOA;EACI,0BAAA;EACA,2KAAA;EAGA,mBAAA;EACA,kBAAA;ACRJ;AAHA;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;AAKJ;AAJI;EACI,aAAA;EACA,gCAAA;EACA,cAAA;AAMR;AALQ;EACI,iBAAA;EACA,+BAAA;EACA,cAAA;AAOZ;AALQ;EACI,yBAAA;AAOZ;AALQ;EACI,YAAA;AAOZ","sourcesContent":["$xs :480px;\n$sm :640px;\n$md : 768px;\n$lg : 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@font-face {\n    font-family: \"Nyght Serif\";\n    src: url('./fonts/NyghtSerif-Light.woff2') format('woff2'),\n         url('./fonts/NyghtSerif-Light.woff') format('woff'),\n         url('./fonts/NyghtSerif-Light.ttf') format('ttf');\n    font-weight: normal;\n    font-style: normal;\n}\n\n$fontFamilyPrimary: 'Nyght Serif', sans-serif;\n$fontFamilySecondary: 'Manrope';\n$primary: #EF2768;\n$primary-hover: #E22160;\n$primary-disabled: #EB83A5;\n$secondary: #DDCABF;\n\n$white: #fff;\n$black: #303030;\n$gray: #C5C5C5;\n$error: red;\n$success: forestgreen;\n\n$bg-color-primary: #FFDDF1;\n$bg-color-secondary: #F9E4D7;\n$bg-color-light: #FFFBF4;\n$bg-ellipse-1: #E3C7FF;\n$bg-ellipse-2: #C7DDFF;\n$bg-ellipse-3: #FFD4C7;\n$bg-ellipse-4: #FEC9D3;","@import '../../assets/styles/constants.scss';\n\n.orderInfo {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid $black;\n    margin-right: 10px;\n    .line {\n        display: flex;\n        border-bottom: 1px solid $black;\n        padding: 5px 0;\n        > div:first-child {\n            font-weight: bold;\n            border-right: 1px solid $black;\n            padding: 0 5px\n        }\n        &:nth-child(2n) {\n            background-color: #e1e1e1;\n        }\n        &:last-child {\n            border: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
