import Footer from './Footer/Footer'
import Header from './Header/Header';

function LayoutDefault({children}) {
  return (
    <div className='wrapperContent'>
      <Header/>
      <div id='content'>
        {children}
      </div>
      <Footer/>
    </div>
  )
}

export default LayoutDefault
