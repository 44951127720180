import {$host} from "./index";

export const getFaceParts = async (part, type, sex) => {
    const {data} = await $host.get('api/load/face-parts/'+part, {params: {type, sex}})
    return data
}

export const getScene = async (name) => {
    const {data} = await $host.get('api/scene/' + name )
    return data
}

export const getScenes = async (params) => {
    const {data} = await $host.get('api/scene/', {params})
    return data
}

export const getLastScene = async (amount = 1) => {
    const {data} = await $host.get('api/scene/last/' + amount )
    return data
}

export const saveScene = async (option) => {
    const {edit, ...scene} = option
    const {data} = edit ? await $host.put('api/scene/' + scene.name, scene) : await $host.post('api/scene/', scene)
    return data
}