import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../UI/Button/Button'
import './ReasonBlock.scss'

function ReasonBlock() {
  const { t } = useTranslation()
  return (
    <div className="reasonBlock">
      <div className='innerWidth paddingPage'>
        <h2>{t('headingReason', { ns: 'main' })}</h2>
        <p className="desc">{t('descReason', { ns: 'main' })}</p>
        <div className="flexColumn w-100">
          <h3>{t('Birthday', { ns: 'main' })}</h3>
          <h3>{t('Wedding', { ns: 'main' })}</h3>
          <h3>{t("Valentine's Day", { ns: 'main' })}</h3>
          <h3>{t("Marriage proposal", { ns: 'main' })}</h3>
          <h3>{t("Wedding anniversary", { ns: 'main' })}</h3>
          <h3>{t("Declaration of love", { ns: 'main' })}</h3>
          <h3>{t("Bachelor party", { ns: 'main' })}</h3>
          <h3>{t("Hen-party", { ns: 'main' })}</h3>
          <Button href="/order" className="secondary">{t('createStoryBtn', { ns: 'UI' })}</Button>
        </div>
      </div>
    </div>
  )
}

export default ReasonBlock
