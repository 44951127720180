import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { login } from "../../API/userAPI"
import { UserContext } from "../../utils/shared/Context"
import useInput from '../../utils/hooks/useInput'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import {catchError} from '../../utils/shared/catchError'

function Login({setRecoverPass}) {
  const { t } = useTranslation()
  const {user} = useContext(UserContext)
  const [isValid, setIsValid] = useState(false)
  const email = useInput('')
  const password = useInput('')

  const validate = useCallback(({email, password}) => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    if (!emailRegex.test(email))
      return false
    return password?.length
  }, [])

  const changeEmail = useCallback(e => {
    const val = e.target.value.trim()
    setIsValid(validate({email: val, password: password.value}))
    email.onChange(e)
  }, [validate, password])

  const changePass = useCallback(e => {
    const val = e.target.value.trim()
    setIsValid(validate({password: val, email: email.value}))
    password.onChange(e)
  }, [validate, email])

  const handleSubmit = useCallback(() => {
    login(email.value, password.value).then(data => {
      if (!data.user)
        throw data
      user.login(data)
    }).catch(err => catchError(err, t))
  }, [user, email, password])

  const openRecoverPass = useCallback(e => {
    e.preventDefault()
    setRecoverPass(prev => !prev)
  }, [])

  const onKeyUp = useCallback(e => {
    if (e.keyCode === 13)
      handleSubmit()
  }, [email, password])

  return (
    <form className="d-flex flex-column">
      <Input {...email} onChange={changeEmail} onKeyUp={onKeyUp} name="email"
        placeholder={t('placeholderEmail', { ns: 'UI' })} className={`${(email.value === '') ? '' : 'filled'}`}/>
      <Input {...password} onChange={changePass} onKeyUp={onKeyUp} name="password"
        type="password" placeholder={t('placeholderPassword', { ns: 'UI' })} className={`${(password.value === '') ? '' : 'filled'}`} />
      <a href="" onClick={openRecoverPass}>{t('forgotPassword', { ns: 'main' })}</a>
      <Button onClick={handleSubmit} disabled={!isValid}>{t('loginBtn', { ns: 'UI' })}</Button>
    </form>
  )
}

export default observer(Login)
