import { useTranslation } from 'react-i18next'
import LayoutDefault from '../../modules/LayoutDefault'

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <h1 className="h3">{t('Privacy policy', { ns: 'policy' })}</h1>
            <p className="mt-15">
              {t('Current privacy policy', { ns: 'policy' })}
              <a href="/" className="text_a" title='LoveInBook'>{location.hostname}</a>,&nbsp;
              {t('take info', { ns: 'policy' })}
              <br/><br/>
              <ol>
                <li>{t('1.', { ns: 'policy' })}</li>
                <p>{t('1.1', { ns: 'policy' })}</p>
                <ul className="list">
                  <li>{t('1.1.1', { ns: 'policy' })}</li>
                  <li>{t('1.1.2', { ns: 'policy' })}</li>
                  <li>{t('1.1.3', { ns: 'policy' })}</li>
                  <li>{t('1.1.4', { ns: 'policy' })}</li>
                  <li>{t('1.1.5', { ns: 'policy' })}</li>
                  <li>{t('1.1.6', { ns: 'policy' })}</li>
                </ul>
                <li>{t('2.', { ns: 'policy' })}</li>
                <ul>
                  <li>{t('2.1', { ns: 'policy' })}</li>
                  <li>{t('2.2', { ns: 'policy' })}</li>
                  <li>{t('2.3', { ns: 'policy' })}</li>
                  <li>{t('2.4', { ns: 'policy' })}</li>
                </ul>
                <li>{t('3.', { ns: 'policy' })}</li>
                <ul>
                  <li>{t('3.1', { ns: 'policy' })}</li>
                  <li>{t('3.2', { ns: 'policy' })}
                    <ul className="list">
                      <li>{t('3.2.1', { ns: 'policy' })}</li>
                      <li>{t('3.2.2', { ns: 'policy' })}</li>
                      <li>{t('3.2.3', { ns: 'policy' })}</li>
                      <li>{t('3.2.4', { ns: 'policy' })}</li>
                    </ul>
                  </li>
                  <li>{t('3.3', { ns: 'policy' })}
                    <ul className="list">
                      <li>{t('3.3.1', { ns: 'policy' })}</li>
                      <li>{t('3.3.2', { ns: 'policy' })}</li>
                      <li>{t('3.3.3', { ns: 'policy' })}</li>
                      <li>{t('3.3.4', { ns: 'policy' })}</li>
                      <li>{t('3.3.5', { ns: 'policy' })}</li>
                      <li>{t('3.3.6', { ns: 'policy' })}</li>
                    </ul>
                  </li>
                  <li>{t('3.4', { ns: 'policy' })}</li>
                  <li>{t('3.5', { ns: 'policy' })}</li>
                  <li>{t('3.6', { ns: 'policy' })}</li>
                </ul>
                <li>{t('4.', { ns: 'policy' })}</li>
                <p>{t('4.1', { ns: 'policy' })}</p>
                <ul className="list">
                  <li>{t('4.1.1', { ns: 'policy' })}</li>
                  <li>{t('4.1.2', { ns: 'policy' })}</li>
                  <li>{t('4.1.3', { ns: 'policy' })}</li>
                  <li>{t('4.1.4', { ns: 'policy' })}</li>
                  <li>{t('4.1.5', { ns: 'policy' })}</li>
                  <li>{t('4.1.6', { ns: 'policy' })}</li>
                  <li>{t('4.1.7', { ns: 'policy' })}</li>
                  <li>{t('4.1.8', { ns: 'policy' })}</li>
                  <li>{t('4.1.9', { ns: 'policy' })}</li>
                  <li>{t('4.1.10', { ns: 'policy' })}</li>
                </ul>
                <li>{t('5.', { ns: 'policy' })}</li>
                <ul>
                  <li>{t('5.1', { ns: 'policy' })}</li>
                  <li>{t('5.2', { ns: 'policy' })}</li>
                  <li>{t('5.3', { ns: 'policy' })}</li>
                  <li>{t('5.4', { ns: 'policy' })}</li>
                  <li>{t('5.5', { ns: 'policy' })}</li>
                  <li>{t('5.6', { ns: 'policy' })}</li>
                </ul>
                <li>{t('6.', { ns: 'policy' })}</li>
                <p>{t('6.1', { ns: 'policy' })}</p>
                <ul className="list">
                  <li>{t('6.1.1', { ns: 'policy' })}</li>
                  <li>{t('6.1.2', { ns: 'policy' })}</li>
                </ul>
                <p>{t('6.2', { ns: 'policy' })}</p>
                <ul className="list">
                  <li>{t('6.2.1', { ns: 'policy' })}</li>
                  <li>{t('6.2.2', { ns: 'policy' })}</li>
                </ul>
                <li>{t('7.', { ns: 'policy' })}</li>
                <ul>
                  <li>{t('7.1', { ns: 'policy' })}</li>
                  <li>{t('7.2', { ns: 'policy' })}</li>
                </ul>
                <ul className="list">
                  <li>{t('7.2.1', { ns: 'policy' })}</li>
                  <li>{t('7.2.2', { ns: 'policy' })}</li>
                </ul>
                <li>{t('8.', { ns: 'policy' })}</li>
                <ul>
                  <li>{t('8.1', { ns: 'policy' })}</li>
                  <li>{t('8.2', { ns: 'policy' })}</li>
                  <li>{t('8.3', { ns: 'policy' })}</li>
                  <li>{t('8.4', { ns: 'policy' })}</li>
                </ul>
                <li>{t('9.', { ns: 'policy' })}</li>
                <ul>
                  <li>{t('9.1', { ns: 'policy' })}</li>
                  <li>{t('9.2', { ns: 'policy' })}</li>
                  <li>{t('9.3', { ns: 'policy' })}</li>
                  <li>{t('9.4', { ns: 'policy' })}: <a href={location.href} className="text_a">{location.href}</a></li>
                </ul>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default PrivacyPolicy