import React, { useEffect, useState } from 'react'
import Button from '../../UI/Button/Button'
import './StatisticBlock.scss'
import { useTranslation } from 'react-i18next'
import { getOrdersCount } from '../../API/orderAPI'

function countOrders(ordersCount = 5) {
    let res = parseInt(ordersCount / 10, 10) * 10
    return res <= 50 ? 50 : res
}

function StatisticBlock() {
  const { t } = useTranslation()
  const [index, setIndex] = useState(1)
  const [ordersCount, setOrdersCount] = useState(50)

  useEffect(() => {
    let slider = setInterval(() => {
        setIndex(index => {
        if (index === 4)
            return 1
        return index + 1
        })
    }, 3000)
    getOrdersCount({})
      .then(res => setOrdersCount(res))
      .catch(err => console.error(err))
    return () => clearInterval(slider)
  }, [])

  return (
    <div className='statisticBlock'>
      <div className='innerWidth paddingPage'>
        <h2>{t('headingStatistic', { ns: 'main' })}</h2>
        <div className="desc" dangerouslySetInnerHTML={{__html: t('descStatistic', { ns: 'main' }) }}></div>
        <div className="flexCenter flexWrap w-100">
          <div className="statLines">
            <div className="statLine">
              <span>15</span>
              <p>{t('creatingTime', { ns: 'main' })}</p>
            </div>
            <div className="statLine">
              <span>5</span>
              <p>{t('productionTime', { ns: 'main' })}</p>
            </div>
            <div className="statLine">
              <span>{countOrders(ordersCount)}+</span>
              <p>{t('ordersAmount', { ns: 'main' })}</p>
            </div>
            <div className="statLine">
              <span>75+</span>
              <p>{t('coversAmount', { ns: 'main' })}</p>
            </div>
            <div className="statLine">
              <span>200</span>
              <p>{t('scenesAmount', { ns: 'main' })}</p>
            </div>
          </div>
          <div className="preview">
            <img className={index === 1 ? 'active' : ''} src="/assets/images/scenes/scene_1.webp" alt="Наші мрії в одній ілюстрації" />
            <img className={index === 2 ? 'active' : ''} src="/assets/images/scenes/scene_2.webp" alt="Наше кохання - ти моя друга половинка" />
            <img className={index === 3 ? 'active' : ''} src="/assets/images/scenes/scene_3.webp" alt="Найкращий мій подакунок - це ти" />
            <img className={index === 4 ? 'active' : ''} src="/assets/images/scenes/scene_4.webp" alt="Ти - мій супергерой" />
          </div>
          <Button href="/order">{t('createStoryBtn', { ns: 'UI' })}</Button>
        <p className='w-100 text-center mt-25'>{t('promo present', { ns: 'main' })}</p>
        </div>
      </div>
    </div>
  )
}

export default StatisticBlock
