import React from 'react'
import defaultConfig from '../default-config.json'
import Input from '../UI/Input/Input'

function OtherTextRow({editScene, setEditScene, curLang}) {

  function removeOtherTextRow(index) {
    const newScene = {...editScene} 
    newScene.otherText.splice(index, 1)
    setEditScene({...newScene})
  }

  function handleOtherText(index, key, lang, value) {
    const newScene = {...editScene}
    newScene.otherText[index][key][lang] = value
    setEditScene({...newScene})
  }

  function handleSetValueArray(index, name, pos, value) {
    const newScene = {...editScene}
    newScene.otherText[index][name][pos] = value != '' ? parseInt(value) : 0
    setEditScene({...newScene})
  }

  return (
    <>
    {editScene && editScene?.otherText.map((text, index) => {
      const pos = editScene.otherText[index].pos;
      const size = editScene.otherText[index].size;
      return <div key={index} className='row'>
        <div className={`removeRow col-10`}>
            <div className="sortRow">:</div>
            <div onClick={() => removeOtherTextRow(index)} className="plus-around">+</div>
        </div>
        <div className={`col-10`}>
            <Input 
                className={pos[0] || pos[0] === 0 ? 'filled' : ''}
                value={pos[0]}
                placeholder="Pos X" 
                onChange={e => handleSetValueArray(index, 'pos', 0, e.target.value)}
            />
        </div>
        <div className={`col-10`}>
            <Input 
                className={pos[1] || pos[1] === 0 ? 'filled' : ''}
                value={pos[1]}
                placeholder="Pos Y"
                onChange={e => handleSetValueArray(index, 'pos', 1, e.target.value)}
            />
        </div>
        <div className={`col-10`}>
            <Input 
                className={size[0] || size[0] === 0 ? 'filled' : ''}
                value={size[0]}
                placeholder="Width" 
                onChange={e => handleSetValueArray(index, 'size', 0, e.target.value)}
            />
        </div>
        <div className={`col-10`}>
            <Input 
                className={size[1] || size[1] === 0 ? 'filled' : ''}
                value={size[1]}
                placeholder="Height"
                onChange={e => handleSetValueArray(index, 'size', 1, e.target.value)}
            />
        </div>
        {defaultConfig.availableLang.map((lang) => {
            return <div key={lang} className={`col-50 ${curLang !== lang ? 'd-none' : ''}`}>
                <Input 
                    className={text['text'][lang] ? 'filled' : ''}
                    value={text['text'][lang]}
                    placeholder={lang.toLocaleUpperCase()} 
                    onChange={e => handleOtherText(index, 'text', lang, e.target.value)}
                />
            </div>
        })}
        </div>
      })}
    </>
  )
}

export default OtherTextRow
