import { $host } from "./index"


export const getOrder = async (id) => {
    const {data} = await $host.get('api/order/' + id)
    return data
}

export const getOrders = async (params = {}) => { //{ids, country, status}
    const {data} = await $host.get('api/order/', {params: {...params}})
    return data
}

export const getOrdersCount = async (params = {}) => { //{ids, country, status}
    const {data} = await $host.get('api/order/count', {params: {...params}})
    return data
}

export const saveOrder = async (option) => {
    const {edit, ...order} = option
    const {data} = edit ? await $host.put('api/order/' + order._id, order) : await $host.post('api/order/', order)
    return data
}

export const addStatistics = async (option) => {
    const {data} = await $host.post('api/order/stat', option)
    return data
}

export const getPaymentHash = async (orderId, payload = {}) => {
    const {data} = await $host.post('api/payment/hash/' + orderId, payload)
    return data
}

export const checkPayment = async (orderId, payload = {}) => {
    const {data} = await $host.post('api/payment/check/' + orderId, payload)
    return data
}

export const createInvoice = async (orderId, payload = {}) => {
    const {data} = await $host.post('api/payment/invoice/' + orderId, payload)
    return data
}