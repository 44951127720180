import { useState } from 'react'

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    (e.target.value !== '') && e.target.parentElement.classList.add("filled")
    setValue(e.target.value)
  }

  return {
    value, 
    onChange
  }
}

export default useInput
