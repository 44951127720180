import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { PROFILE_ROUTE } from '../../utils/consts'
import { UserContext } from '../../utils/shared/Context'
import Authorization from '../../components/Authorization/Authorization'
// import LanguageSelect from '../../components/LanguageSelect/LanguageSelect'
import Button from '../../UI/Button/Button'
import Modal from '../../components/Modal/Modal'
import './Header.scss'

const Header = () => {
  const {user} = useContext(UserContext)
  const { t } = useTranslation()
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const isProfilePage = window.location.pathname === PROFILE_ROUTE

  const showModal = useCallback(() => setIsOpenModal(prev => !prev), [])

  const toggleMenu = useCallback(() => setIsOpenMenu(prev => !prev), [])

  const logout = useCallback(() => user.logout(), [user])

  useEffect(() => {
    user.isAuth && setIsOpenModal(false)
  }, [user.isAuth])

  return (<>
    <header className='header'>
      <div className='flexBetween innerWidth paddingPage'>
        <a href='/' className='logo'>
          <img src="/assets/images/logo.svg" alt="LoveInBook" />
        </a>
        <div className={`headerMenu ${isOpenMenu && 'active'}`}>
          <div className="hamburger" onClick={toggleMenu}><div></div></div>
          <ul>
            <li><a href="/delivery">{t('deliveryMenu', { ns: 'main' })}</a></li>
            <li><a href="/faq">{t('faqMenu', { ns: 'main' })}</a></li>
            <li><a href="/contacts">{t('contactsMenu', { ns: 'main' })}</a></li>
          </ul>
        </div>
        <div className="flexCenter space-between">
          {/* <LanguageSelect/> */}
          {!user.isAuth ? 
            <Button className="login secondary" onClick={showModal}><span className='icon-user'/>{t('loginBtn', { ns: 'UI' })}</Button>:
            (isProfilePage ?
              <Button className="login secondary" onClick={logout}>{t('logoutBtn', { ns: 'UI' })}</Button>:
              <Button href="/profile" className="login secondary"><span className='icon-user'/>{t('profileBtn', { ns: 'UI' })}</Button>
            )}
          <Button className="createStory" href="/order">{t('createStoryBtn', { ns: 'UI' })}</Button>
        </div>
      </div>
    </header>
    <Modal active={isOpenModal} setActive={setIsOpenModal}>
      <Authorization/>
    </Modal>
  </>)
}

export default observer(Header)
