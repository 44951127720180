import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../utils/shared/Context'
import { sendActivationLink } from '../../API/userAPI'
import Button from '../../UI/Button/Button'
import './UserPromoCode.scss'
import {catchError} from '../../utils/shared/catchError'

function UserPromoCode() {
    const { t } = useTranslation()
    const {user} = useContext(UserContext)
    const [isSent, setIsSent] = useState(false)
    const [isPending, setIsPending] = useState(false)

    const activateEmail = useCallback(() => {
        setIsPending(true)
        sendActivationLink(user.user.email)
            .then(res => setIsSent(true))
            .catch(err => catchError(err, t))
            .finally(() => setIsPending(false))
    }, [])

    return (
        <div className="userPromoCode contentBlock">
            <h3>{t('Promocode', { ns: 'main' })}</h3>
            {user.user.isActivated ?
                <p className="promoCode mt-25">LoveInBook</p>:
                <div className='noData'>
                    <p>{isSent ? t('Sent activation', { ns: 'main' }) : t('Activate Email', { ns: 'main' })}</p>
                    {!isSent && <Button onClick={activateEmail} disabled={isPending}>
                        {t('activateEmailBtn', { ns: 'UI' })}
                    </Button>}
                </div>
            }
        </div>
    )
}

export default UserPromoCode
