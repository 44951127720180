import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { OrderContext } from '../../utils/shared/Context'
import './ProgressBar.scss'

function ProgressBar() {
  const {order} = useContext(OrderContext)
  const { t } = useTranslation()

  return (
    <div className='progressContainer'>
      <ul className={`box-item_bg--${order.currentStep}`}>
        {
          order.availableSteps.map((step,key) => {
            return  <li key={key} className={(key < order.currentStep) ? 'box-item_list' : ''}>
                      <span>{t(step, { ns: 'order' })}</span>
                    </li>
          })
        }
      </ul>
    </div>
  )
}

export default observer(ProgressBar)
