import {makeAutoObservable} from "mobx";
import getLangBySubdomain from "../utils/getLangBySubdomain";

class UserState {
    constructor() {
        this._isAuth = false
        this._user = {}
        this._lang = (localStorage.getItem('lang') != null) ? localStorage.getItem('lang') : getLangBySubdomain(true);
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }

    setUser(user) {
        this._user = user
    }

    setLang(lang) {
        this._lang = lang
    }

    login(data) {
        this.setUser(data.user)
        this.setIsAuth(true)
        localStorage.setItem('token', data.accessToken)
    }

    logout() {
        this.setUser({})
        this.setIsAuth(false)
        localStorage.removeItem('token')
    }

    get isAuth(){
        return this._isAuth
    }

    get user(){
        return this._user
    }

    get lang() {
      return this._lang
    }
}

export default UserState