import './Tab.scss'

function Tab({dataTopic, selected, onClick, children}) {
  return (
    <a 
      className={`tab ${selected ? 'selected' : ''}`}
      data-topic={dataTopic}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default Tab
