import React from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'

function Stats() {
  return (
    <LayoutAdmin>
      <div className='test'>
        <img src="/assets/face_parts/color/man/hair/hair_001_black.svg" alt="" />
      </div>
    </LayoutAdmin>
  )
}

export default Stats
