
class storageProvider {

  getItem(key) {
    const value = localStorage.getItem('order') || '{}'
    const order = JSON.parse(value)
    return typeof order[key] !== 'undefined' ? order[key] : null
  }

  setItem(key, value) {
    const order = localStorage.getItem('order') 
      ? JSON.parse(localStorage.getItem('order')) 
      : {}
    order[key] = value
    localStorage.setItem('order', JSON.stringify(order))
  }

  removeItem(key) {
    localStorage.removeItem(key)
  }
  
  enumerateKeys() {
    return Object.keys(localStorage)
  }
}

export default new storageProvider()
