// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container .tabs {
  justify-content: flex-start;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Tabs/Tabs.scss"],"names":[],"mappings":"AACI;EACI,2BAAA;EACA,UAAA;AAAR","sourcesContent":[".tabs-container {\n    .tabs {\n        justify-content: flex-start;\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
