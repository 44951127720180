import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './CreateBlock.scss'

const maxSlides = 4

function CreateBlock() {
  const { t } = useTranslation()
  const [curSlide, setCurSlide] = useState(1)

  return (
    <div className='createBlock'>
        <div className='innerWidth paddingPage'>
            <h2 dangerouslySetInnerHTML={{__html: t('4 steps create comic', { ns: 'main' }) }}></h2>
            <div className="stepSlider">
              <div className="preview">
                <img className={curSlide === 1 ? 'active' : ''} src="../../assets/images/steps/step_1.webp" alt="" />
                <img className={curSlide === 4 ? 'active' : ''} src="../../assets/images/steps/step_4.webp" alt="" />
                <img className={curSlide === 3 ? 'active' : ''} src="../../assets/images/steps/step_3.webp" alt="" />
                <img className={curSlide === 2 ? 'active' : ''} src="../../assets/images/steps/step_2.webp" alt="" />
              </div>
              <div className="steps">
                <div className="arrows">
                  <div className={`prev ${curSlide === 1 ? 'disabled' : ''}`} onClick={() => setCurSlide(curSlide-1)}><div></div></div>
                  <div className={`next ${curSlide === maxSlides ? 'disabled' : ''}`} onClick={() => setCurSlide(curSlide+1)}><div></div></div>
                </div>
                <div className="number">0{curSlide} / 0{maxSlides}</div>
                <div className="text">
                  <div className={curSlide === 1 ? 'active' : ''}>
                    <h3>{t('Assemble the characters of your book', { ns: 'main' })}</h3>
                    <p>{t('Choose the color of the skin, eyes, nose, hairstyle', { ns: 'main' })}</p>
                  </div>
                  <div className={curSlide === 2 ? 'active' : ''}>
                    <h3>{t('Choose a cover that reflects the reason for the gift', { ns: 'main' })}</h3>
                    <p>{t('Choose the cover you need from our rich collection', { ns: 'main' })}</p>
                  </div>
                  <div className={curSlide === 3 ? 'active' : ''}>
                    <h3>{t('Build your own story from many scenes', { ns: 'main' })}</h3>
                    <p>{t('We have prepared romantic', { ns: 'main' })}</p>
                  </div>
                  <div className={curSlide === 4 ? 'active' : ''}>
                    <h3>{t('Place your order', { ns: 'main' })}</h3>
                    <p>{t('Having arranged the scenes on the pages', { ns: 'main' })}</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default CreateBlock
