import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../UI/Button/Button'

export default function PaymentStatusNew({order, pending}) {
  const { t } = useTranslation()

  return <>
      <h1>{t('New payment title', { ns: 'main' })}</h1><br/><br/>
      <div>
        {t('order number', { ns: 'main' })}:<br/>
        <strong>#{order._id}</strong>
        {pending && <div className="mt-15" style={{margin: '0 auto', maxWidth: '750px'}}>
          {t('pending order', { ns: 'main' })}<br/>
          {t('rebuild order', { ns: 'main' })}&nbsp;
          <a href='/order' className="text_a">{t('rebuild order link', { ns: 'main' })}</a>.
        </div>}
        <div className="flexColumn mt-15">
          <a href={order.payment.invoiceUrl}>
            {order.payment.qrCode && <img src={order.payment.qrCode} alt='payment'/>}
          </a>
          {order.payment.invoiceUrl && <Button href={order.payment.invoiceUrl} className="mt-25">
            {t('orderPaymentBtn', { ns: 'UI' })}
          </Button>}
        </div>
      </div>
    </>
  }
