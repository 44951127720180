import React from 'react'
import { useTranslation } from 'react-i18next'
import './InsideBlock.scss'

function InsideBlock() {
  const { t } = useTranslation()
  return (
    <div className='insideBlock'>
        <div className='innerWidth paddingPage'>
            &nbsp;
            <p className="slogan">
                <span>{t('Creative gift', { ns: 'main' })}</span>
                <span>{t('from heart', { ns: 'main' })}</span>
            </p>
        </div>
    </div>
  )
}

export default InsideBlock
