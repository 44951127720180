import defaultConfig from '../default-config.json'

const getLangBySubdomain = (local = false) => {
  const locationParts = window.location.hostname.split('.');
  const lang = locationParts[0];

  if (defaultConfig.availableLang.includes(lang)) 
    return (!local && lang === 'ua') ? 'uk' : lang;

  return (!local) ? 'uk' : 'ua'
}

export default getLangBySubdomain;