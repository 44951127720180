import {$authHost, $host} from "./index";
import axios from 'axios'

export const registration = async (email, password) => {
  const role = (process.env.REACT_APP_ADMIN_EMAIL === email) ? 'admin' : 'user';
  const {data} = await $authHost.post('api/auth/registration', {email, password, role})
  return data
}

export const login = async (email, password) => {
  const {data} = await $authHost.post('api/auth/login', {email, password})
  return data
}

export const sendActivationLink = async (email) => {
  const {data} = await $authHost.get('api/auth/send-activation/' + email)
  return data
}

export const checkAuth = async () => {
  const {data} = await $host.get('api/auth/refresh')
  return data
}

export const updateInfo = async (contactInfo) => {
  const {data} = await $authHost.post('api/auth/update', contactInfo)
  return data
}

export const getIP = async () => {
  try {
    const res = await fetch("https://api.ipify.org/?format=json")
    const client = await res.json() || {}
    return client.ip
  } catch (e) {
    return ''
  }
}
