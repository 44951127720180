import React from 'react'
import './Modal.scss'

function Modal({active, setActive, maxWidth, children}) {

  return (
    <div
      className={`modal-fon ${active ? 'active' : ''}`}
      onClick={() => setActive(false)}
    >
      <div className='modal' style={{maxWidth: maxWidth}} onClick={e => e.stopPropagation()}>
        <div className="close" onClick={() => setActive(false)}></div>
        {children}
      </div>
    </div>
  )
}

export default Modal
