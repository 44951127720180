import { useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from "../../utils/shared/Context"
import { SCENES_ROUTE, ADMIN_ROUTE, ORDERS_ROUTE, STATS_ROUTE, VOUCHERS_ROUTE, COVERS_ROUTE } from '../../utils/consts'
import './NavBar.scss'

const adminMenu = [
  {path: ADMIN_ROUTE, text: 'Dashboard'},
  {path: SCENES_ROUTE, text: 'Scenes'},
  {path: STATS_ROUTE, text: 'Statistics'},
  {path: ORDERS_ROUTE, text: 'Orders'},
  {path: VOUCHERS_ROUTE, text: 'Vouchers'},
  {path: COVERS_ROUTE, text: 'Covers'},
]


function NavBar({currentPath}) {
  const {user} = useContext(UserContext)

  const logout = useCallback(() => {
    user.setIsAuth(false)
  }, [user])

  return (
    <>
      <div className='navbarTop'>
        <Link to='/'>{'<'} GO to home</Link>
        <a onClick={logout}>Logout</a>
      </div>
      <ul className='navbar'>
        {adminMenu.map((item) => (
          <li key={item.text} className={currentPath === item.path ? 'selected' : ''}>
            <Link to={item.path}>{item.text}</Link>
          </li>
        ))}
      </ul>
    </>
  )
}

export default NavBar
