import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import useInput from '../../utils/hooks/useInput'

function Forgot({setRecoverPass}) {
    const { t } = useTranslation()
    const [isValid, setIsValid] = useState(false)
    const email = useInput('')

    const closeRecoverPass = useCallback(() => setRecoverPass(prev => !prev), [])

    const changeEmail = useCallback(e => {
        const val = e.target.value.trim()
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
        setIsValid(emailRegex.test(val))
        email.onChange(e)
    }, [])

    const handleSubmit = useCallback(() => {

    }, [])

    return (
        <div className="authorization">
            <h2 className="m-auto">
                <div className="back" onClick={closeRecoverPass}><span className="icon-arrow-l"></span></div>
                {t('authForgotTitle', { ns: 'main' })}</h2>
            <p className="info">{t('recoveryPassword', { ns: 'main' })}</p>
            <form>
                <Input {...email} onChange={changeEmail}
                    placeholder={t('placeholderEmail', { ns: 'UI' })} className={`${(email.value === '') ? '' : 'filled'}`}/>
                <Button onClick={handleSubmit} disabled={!isValid}>{t('recoveryBtn', { ns: 'UI' })}</Button>
            </form>
        </div>
    )
}

export default Forgot
