import React from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { ADMIN_ROUTE } from '../../utils/consts'

function Dashboard() {
  return (
    <LayoutAdmin currentPath={ADMIN_ROUTE}>
      Dashboard
    </LayoutAdmin>
  )
}

export default Dashboard
