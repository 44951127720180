import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import getLangBySubdomain from './utils/getLangBySubdomain'
import './i18n'
import './assets/styles/global.scss'

document.documentElement.setAttribute('lang', getLangBySubdomain())

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App/>)
