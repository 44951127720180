import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { checkPayment, saveOrder } from '../../API/orderAPI'
import { getPaymentData, purchaseOrder } from '../../store/paymentProvider'
import { UserContext } from '../../utils/shared/Context'
import PaymentStatusNew from './PaymentStatusNew'

function PaymentStatusView({order, updateOrder}) {
  const { t } = useTranslation()
  const {user} = useContext(UserContext)
  const status = order.payment?.status
  const checkPeriodical = useRef()

  const changeOrder = useCallback(paymentStatus => {
    const status = paymentStatus === 'Approved' ? 'done' : 'pending'
    if (status === order.payment.status)
      return null

    const changedOrder = {
      ...order,
      edit: true,
      payment: {...order.payment, status}
    }
    saveOrder(changedOrder)
      .then(res => updateOrder(changedOrder))
      .catch(err => console.error(err))
    //reset client book history
    if (status === 'done') {
      localStorage.set('order', '')
    }
  }, [order])

  const buildPaymentInvoice = useCallback(async (order) => {
    const invoice = await purchaseOrder(order, user.lang)
    if (!invoice.invoiceUrl)
      return null

    window.open(invoice.invoiceUrl)

    const changedOrder = {
      ...order,
      edit: true,
      payment: {
        method: 'card',
        status: 'pending',
        invoiceUrl: invoice.invoiceUrl,
        qrCode: invoice.qrCode
      }
    }
    saveOrder(changedOrder)
      .then(res => updateOrder(changedOrder))
      .catch(err => console.error(err))
  }, [user])

  const checkPaymentStatus = useCallback(() => {
    const data = getPaymentData({order, type: "check"})
    checkPayment(order._id, data).then(payment => {
      if (!order.payment.invoiceUrl)
        return buildPaymentInvoice(order)

      changeOrder(payment.transactionStatus)
    }).catch(err => console.error(err))
  }, [order])

  useEffect(() => {
    if (['new', 'pending'].includes(order?.payment.status))
      checkPaymentStatus()
    if (order?.payment.status === 'pending') {
      checkPeriodical.current = setInterval(() => checkPaymentStatus(), 5000)
    }
    return () => clearInterval(checkPeriodical.current)
  }, [order, checkPeriodical])

  switch (status) {
    case 'new': return <PaymentStatusNew order={order}/>
    case 'pending': return <PaymentStatusNew order={order} pending/>
    case 'done':
    default: return <>
      <h1>{t('Thanks title', { ns: 'main' })}</h1><br/><br/>
      <div style={{margin: '0 auto', maxWidth: '750px'}}>
        {t('order number', { ns: 'main' })}:<br/>
        <strong>#{order._id}</strong>
        <br/><br/>
        {t('about order', { ns: 'main' })}
        <br/><br/>
        {t('order ready', { ns: 'main' })}
        <br/><br/>
        {t('check order', { ns: 'main' })}
      </div>
    </>
  }
}

export default PaymentStatusView