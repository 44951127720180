import React from 'react'
import appSettings from '../../store/appSettings'
import './SocialChanel.scss'


const unformattedPhone = (phone) => {
    return phone.replace(/[() -]/g,'')
}

function SocialChanel() {

    return (
        <div className="socialChanel">
            <a href={`mailto:${appSettings.email}`}>
                <span className="icon-email"/>
                {appSettings.email}</a>
            <a href={`tel:${unformattedPhone(appSettings.phone)}`}>
                <span className="icon-phone"/>
                {appSettings.phone}</a>
            <a href={appSettings.insta}>
                <span className="icon-instagram"/>
                Instagram</a>
            <a href={appSettings.facebook}>
                <span className="icon-facebook"/>
                Facebook</a>
            <a href={appSettings.telegram}>
                <span className="icon-telegram"/>
                Telegram</a>
        </div>
    )
}

export default SocialChanel
