import {BrowserRouter} from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppRouter from './components/AppRouter';
import UserStore from './store/UserState'
import { UserContext } from "./utils/shared/Context";
import {ToastNotificationsBlock} from './toast/toast-notifications-block'
import setDeviceViewSize from './utils/setDeviceViewSize'

const App = (root) => {
  const { t } = useTranslation()
  document.title = t('titleSite', 'main')
  setDeviceViewSize()

  return (
      <UserContext.Provider value={{
        user: new UserStore()
      }}>
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
        <ToastNotificationsBlock/>
      </UserContext.Provider>
  )
}

export default App
