import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getOrders } from '../../API/orderAPI'
import { ORDERS_ROUTE } from '../../utils/consts'
import {catchError} from '../../utils/shared/catchError'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'

function Orders() {
  const [orderList, setOrderList] = useState([])
  const { t } = useTranslation()
  const columnsTable = ['country', 'status', 'payment', 'createdAt', 'total', 'action']

  useEffect(() => {
    getOrders({})
      .then(res => setOrderList(res))
      .catch(err => catchError(err, t))
  }, [])

  return (
    <LayoutAdmin currentPath={ORDERS_ROUTE}>
      <h1 className='title'>Orders ({orderList.length})</h1>
      <table className='listOrders'>
        <tbody>
          <tr>{columnsTable.map(td => <th key={td}>{td}</th>)}</tr>
          {orderList.map(order => {
            return <tr key={order._id}>
                <td>{order.country}</td>
                <td>{order.status}</td>
                <td>{order.payment.status}</td>
                <td>{order.createdAt}</td>
                <td>{order.total.price} {order.total.currency}</td>
                <td>
                  <Link to={`${ORDERS_ROUTE}/${order._id}`}>Process</Link>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
    </LayoutAdmin>
  )
}

export default Orders
