import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import { useMask } from '@react-input/mask'
import appSettings from '../../store/appSettings'
import './Input.scss'

const Input = forwardRef(({value, className, type = 'text', name = 'name', placeholder, onChange, onKeyUp, mask}, ref) => {
  const inputRef = useMask({ mask: (appSettings.phoneCode || '+380') + ' (__) ___-__-__', replacement: { _: /\d/ } })

  function change(e) {
    e.target.parentElement.classList.add("filled")
    onChange(e)
  }

  function handleBlur(e) {
    e.target.value = e.target.value.trim()
    e.target.value === '' && e.target.parentElement.classList.remove("filled")
  }

  return (
    <div className={`inputContainer ${className ? className : ''}`}>
      <input
        ref={ref ? ref : mask && inputRef}
        name={name && type}
        type={type}
        value={value ?? ''}
        onChange={change}
        onKeyUp={onKeyUp}
        onBlur={handleBlur}
      />
      {placeholder && <div className='placeholder'>{placeholder}</div>}
    </div>
  )
})

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Input