import React from 'react'
import {OrderContext} from '../../utils/shared/Context'
import OrderState from '../../store/OrderState'
import NavBar from '../NavBar/NavBar'
import './LayoutAdmin.scss'
import AuthLayout from '../../pages/Auth/AuthLayout'

export default function LayoutAdmin({currentPath, children}) {
  return (
    <AuthLayout>
      <OrderContext.Provider value={{
        order: new OrderState({
          lipsPosition: {
            "man": "m",
            "woman": "l"
          },
          selectedFaceParts: {
            "man": {
              "decorations": "no",
              "zest": "zest_006",
              "eyebrows": "eyebrows_008_10",
              "eye": "eye_007_8",
              "lips": "lips_013_8_m",
              "hair": "hair_009_7",
              "beard": "beard_008_2",
              "nose": "nose_009",
              "glasses": "no"
            },
            "woman": {
              "zest": "zest_015",
              "eyebrows": "eyebrows_007_11",
              "eye": "eye_008_9",
              "lips": "lips_008_3_l",
              "decorations": "decorations_005",
              "hair": "hair_012_21",
              "beard": "no",
              "nose": "nose_008",
              "glasses": "no"
            }
          },
          selectedFacePartsColor: {
            "man": {
                "zest": "no",
                "decorations": "color_10",
                "eye": "color_1",
                "eyebrows": "color_10",
                "lips": "color_8",
                "hair": "color_10",
                "beard": "color_7",
                "nose": "no",
                "glasses": "no"
            },
            "woman": {
                "zest": "no",
                "decorations": "no",
                "eye": "color_1",
                "eyebrows": "color_4",
                "lips": "color_8",
                "hair": "color_22",
                "beard": "no",
                "nose": "no",
                "glasses": "no"
            }
          }
        })
      }}>
        <div className='adminLayout'>
          <NavBar currentPath={currentPath}/>
          <div id='content'>
              {children}
          </div>
        </div>
      </OrderContext.Provider>
    </AuthLayout>
  );
}