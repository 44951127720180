import React from 'react'
import './Select.scss'

function Select({options, value, onChange, children, dataId}) {
  return (
    <select className='select' data-id={dataId} value={value} onChange={onChange}>
        {children}
        {options && options.map(option => {
            return <option
                key={dataId + (option.value || option)}
                value={option.value || option}
            >{option.name || option}</option>
        })}
    </select>
  )
}

export default Select
