import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../UI/Button/Button'
import './PromoBlock.scss'

function PromoBlock() {
  const { t } = useTranslation()

  return (
    <>
      <div className='promoBlockFon'/>
      <div className='promoBlock'>
        <div className='innerWidth paddingPage'>
          <img className="man_body" src="../../assets/images/man_body.webp" alt="Man LoveInBook"/>
          <img className="woman_body" src="../../assets/images/woman_body.webp" alt="Woman LoveInBook"/>
          <h1>{t('headingPage', {ns: 'main'})}</h1>
          <p className="desc">{t('descPage', {ns: 'main'})}</p>
          <Button href="/order" className="secondary">{t('letsTryBtn', {ns: 'UI'})}</Button>
        </div>
      </div>
    </>
  )
}

export default PromoBlock
