import React, { useEffect, useRef } from 'react'
import { OrderContext } from '../../../utils/shared/Context'
import { checkOrderStorage } from '../../../utils/checkOrderStorage'
import OrderState from './../../../store/OrderState'
import OrderSteps from '../../../modules/OrderSteps'
import Header from './../../../modules/Header/Header'
import './Order.scss'

function Order() {

  return (
    <OrderContext.Provider value={{
      order: new OrderState(checkOrderStorage())
    }}>
      <Header/>
      <OrderSteps/>
    </OrderContext.Provider>
  )
}

export default Order
