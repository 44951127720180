import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../utils/shared/Context'
import useInput from '../../utils/hooks/useInput'
import Input from '../../UI/Input/Input'
import appSettings from '../../store/appSettings'
import Button from '../../UI/Button/Button'
import './ContactInfo.scss'
import { updateInfo } from '../../API/userAPI'
import {catchError} from '../../utils/shared/catchError'

function ContactInfo() {
    const { t } = useTranslation()
    const {user} = useContext(UserContext)
    const [contactInfo, setContactInfo] = useState({...user.user})
    const [isValid, setIsValid] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const name = useInput(contactInfo.name)
    const middle = useInput(contactInfo.middle)
    const surname = useInput(contactInfo.surname)
    const phoneNumber = useInput(contactInfo.phoneNumber ? contactInfo.phoneNumber : appSettings.phoneCode || '+380 ')

    const validate = useCallback(({name, middle, surname, phoneNumber}) => {
        if (!name || !middle || !surname)
            return setIsValid(false)
        if (!phoneNumber?.length)
            return setIsValid(false)
        return setIsValid(true)
    })

    const changeName = useCallback(e => {
        const val = e.target.value
        setContactInfo(prev => {
            const info = {...prev, name: val}
            validate(info)
            return info
        })
        name.onChange(e)
    }, [name])

    const changeMiddle = useCallback(e => {
        const val = e.target.value
        setContactInfo(prev => {
            const info = {...prev, middle: val}
            validate(info)
            return info
        })
        middle.onChange(e)
    }, [middle])

    const changeSurname = useCallback(e => {
        const val = e.target.value
        setContactInfo(prev => {
            const info = {...prev, surname: val}
            validate(info)
            return info
        })
        surname.onChange(e)
    }, [surname])

    const changePhone = useCallback(e => {
        const val = e.target.value
        setContactInfo(prev => {
            const info = {...prev, phoneNumber: val}
            validate(info)
            return info
        })
        phoneNumber.onChange(e)
    }, [phoneNumber])

    const onSave = useCallback(() => {
        setIsPending(true)
        updateInfo({email: user.user.email, ...contactInfo})
            .then(res => {
                window.notify({
                    type: 'success',
                    message: t('Contact information saved', { ns: 'main' })
                })
            })
            .catch(err => catchError(err, t))
            .finally(() => setIsPending(false))
    }, [contactInfo])

    const onKeyUp = useCallback(e => {
        if (e.keyCode === 13)
            onSave()
    }, [name, middle, surname, phoneNumber])

    return (
        <div className="contactInfo contentBlock">
            <h3>{t('Contact information', { ns: 'main' })}</h3>
            <div className="row bigSpace">
                <div className="col col-33 mt-25">
                    <Input {...name} onChange={changeName} onKeyUp={onKeyUp}
                        placeholder={t('First name', { ns: 'order' })}
                        className={`${(name.value === '') ? '' : 'filled'}`}/>
                </div>
                <div className="col col-33 mt-25">
                    <Input {...middle} onChange={changeMiddle} onKeyUp={onKeyUp}
                        placeholder={t('Middle name', { ns: 'order' })}
                        className={`${(middle.value === '') ? '' : 'filled'}`}/>
                </div>
                <div className="col col-33 mt-25">
                    <Input {...surname} onChange={changeSurname} onKeyUp={onKeyUp}
                        placeholder={t('Last name', { ns: 'order' })}
                        className={`${(surname.value === '') ? '' : 'filled'}`}/>
                </div>
            </div>
            <div className="row bigSpace between alignEnd">
                <div className="col col-33 mt-25">
                    <Input {...phoneNumber} onChange={changePhone} onKeyUp={onKeyUp} mask //+380 (__) ___-__-__
                        placeholder={t('Phone', { ns: 'order' })}
                        className={`${(phoneNumber.value === '') ? '' : 'filled'}`}/>
                </div>
                <div className="col col-33 mt-25">
                    <Button onClick={onSave} disabled={!isValid || isPending}>{t('saveBtn', { ns: 'UI' })}</Button>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo
