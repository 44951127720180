import React from 'react'
import { useTranslation } from 'react-i18next'
import LayoutDefault from '../modules/LayoutDefault'

const Page404 = () => {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const activation = params.get('activation')

  return (
    <LayoutDefault>
      <div className="flexCenter flexGrow text-center" style={{padding: '100px 15px', minHeight: '500px'}}>
        <h1>{activation ? t('Not found activation', { ns: 'errors' }) : t('Not found page', { ns: 'errors' })}</h1>
      </div>
    </LayoutDefault>
  )
}

export default Page404
