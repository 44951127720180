import { $host } from "./index"


export const checkVoucher = async (code) => {
    const {data} = await $host.get('api/voucher/' + code)
    return data
}

export const getVouchers = async () => {
    const {data} = await $host.get('api/voucher/')
    return data
}

export const saveVoucher = async (option) => {
    const {edit, ...voucher} = option
    const {data} = edit ? await $host.put('api/voucher/' + voucher.code, voucher) : await $host.post('api/voucher/', voucher)
    return data
}