import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '../../UI/Input/Input'
import Select from '../../UI/Select/Select'
import Button from '../../UI/Button/Button'
import { checkVoucher, saveVoucher } from '../../API/voucherAPI';
import './VoucherCreator.scss'

function VoucherCreator({code}) {
    const navigate = useNavigate()
    const [voucher, setVoucher] = useState()
    const options = [{value: "super_admin", name: "Super Admin"},{value: "others", name: "Others"}]

    useEffect(() => {
        code ? (async ()=>{
            const voucherData = await checkVoucher(code)
            setVoucher({...voucherData, edit: true})
        })() : setVoucher({
            code: 'NEW_USER',
            value: '5',
            user: 'super_admin',
            cashback: '0'
        })
    }, [])

    function handleChange(val, key) {
        const newVoucher = {
            ...voucher,
            [key]: val
        }
        setVoucher(newVoucher)
    }

    async function save() {
        await saveVoucher({...voucher, edit: !!code}).catch(e => console.error(e))
        navigate('/admin/vouchers/')
    }

    return (
        <>{voucher && <div className='voucherCreator'>
            <div className="row">
                <div className="col col-50">
                    <Input value={voucher.code} onChange={e => handleChange(e.target.value, "code")} placeholder="Code" className='filled'/>
                </div>
                <div className="col col-50 mt-15">
                    <Select options={options} value={options[0]} onChange={e => handleChange(e.target.value, "user")}/>
                </div>
            </div>
            <div className="row">
                <div className="col col-50">
                    <Input value={voucher.value} onChange={e => handleChange(e.target.value, "value")} placeholder="Value (%)" className='filled'/>
                </div>
                <div className="col col-50">
                    <Input value={voucher.cashback} onChange={e => handleChange(e.target.value, "cashback")} placeholder="Cash back (%)" className='filled'/>
                </div>
            </div>
            <Button className='bg-primary mt-15' onClick={save}>Save</Button>
        </div>}</>
    )
}

export default VoucherCreator
