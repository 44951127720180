import React, { Suspense, useCallback, useContext, useEffect, useRef } from 'react'
import { OrderContext } from '../utils/shared/Context';
import { observer } from 'mobx-react-lite';
import ProgressBar from '../components/ProgressBar/ProgressBar'
import FooterOrder from '../components/FooterOrder/FooterOrder'
import { throttle } from 'throttle-debounce'
import Loading from '../UI/Loading/Loading';

function OrderSteps() {
  const {order} = useContext(OrderContext)
  const CurrentStep = React.lazy(() => import(`../components/OrderSteps/Step_${order.currentStep}/Step_${order.currentStep}`));
  const wrap = useRef(null)

  useEffect(() => {
    setTimeout(() => order.autoSavingSelectedParts(), 10)
  }, [])

  useEffect(() => {
    wrap.current?.classList.remove('highest')
    setTimeout(detectFooterPos, 600)
    window.addEventListener('scroll', throttle(200, detectFooterPos))
    window.addEventListener('resize', throttle(200, detectFooterPos))
    return () => {
      window.removeEventListener('scroll', throttle(200, detectFooterPos))
      window.removeEventListener('resize', throttle(200, detectFooterPos))
    }
  }, [order.currentStep])

  const detectFooterPos = useCallback(() => {
    if (![0,1,2,6].includes(order.currentStep)) {
      const wrapHeight = wrap.current?.offsetHeight
      const screenHeight = window.screen.height
      const scrollHeight = window.scrollY
      if (wrapHeight >= (screenHeight + scrollHeight + 100)) {
        wrap.current?.classList.add('highest')
      } else {
        wrap.current?.classList.remove('highest')
      }
    }
  }, [])

  return (
    <div className="innerWidth paddingPage">
      <div ref={wrap} data-step={order.OrderSteps} className='orderWrapper'>
        <ProgressBar/>
        <Suspense fallback={<Loading/>}>
          <CurrentStep step={order.currentStep}/>
        </Suspense>
        <FooterOrder/>
      </div>
    </div>
  )
}

export default observer(OrderSteps)
