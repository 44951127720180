import React from 'react'
import { useTranslation } from 'react-i18next'
import './Loading.scss'

function Loading({fullScreen}) {
  const { t } = useTranslation()

  return (
    <div className={`loading ${fullScreen && 'fullScreen'}`}>
      <img src="/assets/images/logo.svg" alt="Loading..."/>
      {fullScreen && <p>{t('loading', { ns: 'UI' })}</p>}
    </div>
  )
}

export default Loading
