import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Authorization.scss'
import Login from './Login'
import Registration from './Registration'
import Forgot from './Forgot'

const tabs = ['login', 'registration']

function Authorization({isAdminPart}) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [isRecoverPass, setIsRecoverPass] = useState(false)

  const selectTab = useCallback(e => {
    if (!e.target.classList.length)
      setActiveTab(prev => (prev === tabs[0]) ? tabs[1] : tabs[0])
  }, [])

  if (isRecoverPass)
    return <Forgot setRecoverPass={setIsRecoverPass}/>

  return (
    <div className="authorization">
      <h2 className="m-auto">{t('authTitle', { ns: 'main' })}</h2>
      {!isAdminPart && <div className="tabs">
        <div className={(activeTab === tabs[0]) ? 'active' : ''} onClick={selectTab}>{t('authLoginTitle', { ns: 'main' })}</div>
        <div className={(activeTab === tabs[1]) ? 'active' : ''} onClick={selectTab}>{t('authRegistrationTitle', { ns: 'main' })}</div>
      </div>}
      {(activeTab === tabs[0]) ? <Login setRecoverPass={setIsRecoverPass}/> : <Registration/>}
    </div>
  )
}

export default Authorization
