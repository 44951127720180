import { useTranslation } from 'react-i18next'
import LayoutDefault from '../../modules/LayoutDefault'

const Delivery = () => {
  const { t } = useTranslation()

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <h1 className="h3">{t('deliveryMenu', { ns: 'main' })}</h1>
            <p className="mt-15">
              {t('Delivery info', { ns: 'main' })}
            </p><br/>
            <h3 className="h3 mt-15">{t('Payment', { ns: 'main' })}</h3>
            <p className="mt-15">
              {t('Payment info', { ns: 'main' })}
            </p><br/>
            <h3 className="h3 mt-15">{t('Return', { ns: 'main' })}</h3>
            <p className="mt-15">
              {t('Return info', { ns: 'main' })}
            </p>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Delivery