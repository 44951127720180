import { useTranslation } from 'react-i18next'
import LayoutDefault from '../../modules/LayoutDefault'

const Faq = () => {
  const { t } = useTranslation()

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <h1 className="h3">{t('FAQ title', { ns: 'FAQ' })}</h1>
            <p className="mt-15">
              <strong>{t('Q1 title', { ns: 'FAQ' })}</strong><br />
              {t('Q1 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q2 title', { ns: 'FAQ' })}</strong><br />
              {t('Q2 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q3 title', { ns: 'FAQ' })}</strong><br />
              {t('Q3 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q4 title', { ns: 'FAQ' })}</strong><br />
              {t('Q4 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q5 title', { ns: 'FAQ' })}</strong><br />
              {t('Q5 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q6 title', { ns: 'FAQ' })}</strong><br />
              {t('Q6 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q7 title', { ns: 'FAQ' })}</strong><br />
              {t('Q7 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q8 title', { ns: 'FAQ' })}</strong><br />
              {t('Q8 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q9 title', { ns: 'FAQ' })}</strong><br />
              {t('Q9 text', { ns: 'FAQ' })}
            </p>
            <p className="mt-15">
              <strong>{t('Q10 title', { ns: 'FAQ' })}</strong><br />
              {t('Q10 text', { ns: 'FAQ' })}
            </p>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Faq