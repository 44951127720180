import { createInvoice, getPaymentHash } from '../API/orderAPI'
import defaultConfig from '../default-config.json'

export default async function buildInvoice({order, signature, lang}) {
    const data = getPaymentData({order, signature, lang})
    const invoice = await createInvoice(order._id, data)
    return invoice
}

export function getPaymentData({order = {}, signature, lang="EN", type = 'order'}) {
    const {_id: id, total, contactInfo, createdAt} = order
    const price = total?.price
    const defaultData = {
        merchantAccount: process.env.REACT_APP_WAYFORPAY_MERCHANT_ACCOUNT,
        orderReference: id
    }
    if (type === 'check')
        return defaultData

    const signData = {
        merchantAccount: process.env.REACT_APP_WAYFORPAY_MERCHANT_ACCOUNT,
        merchantDomainName: location.hostname,
        orderReference: id,
        orderDate: Math.floor(new Date(createdAt).getTime() / 1000),
        amount: price,
        currency: defaultConfig.prices['ua']?.currencyCode || "UAH",
    }
    if (type === 'signature')
        return {
            ...signData,
            "productName": "LoveInBook",
            "productCount": 1,
            "productPrice": price
        }

    const purchaseData = {
        ...signData,
        authorizationType : "SimpleSignature",
        merchantSignature : signature,
        clientFirstName : contactInfo.name,
        clientLastName : contactInfo.surname,
        clientPhone: contactInfo.phone,
        //orderTimeout: 1000 * 60 * defaultConfig.paymentTimeout, //wait for pay 10 min
        returnUrl: process.env.REACT_APP_SERVER_API_URL + 'api/payment/redirect/' + id,
        language: lang,
        "productName": ["LoveInBook"],
        "productCount": [1],
        "productPrice": [price]
    }

    return purchaseData
}

export async function purchaseOrder(order, lang) {
    const data = getPaymentData({order, type: 'signature'})
    const {signature} = await getPaymentHash(order._id, data)
    return await buildInvoice({order, lang, signature})
}