import { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { UserContext } from "../../utils/shared/Context";
import { registration } from "../../API/userAPI";
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import useInput from '../../utils/hooks/useInput';
import {catchError} from '../../utils/shared/catchError'

function Registration() {
  const { t } = useTranslation()
  const {user} = useContext(UserContext)
  const [isValid, setIsValid] = useState(false)
  const email = useInput('')
  const password = useInput('')
  const confirmPass = useInput('')

  const validate = useCallback(({email, password, confirm}) => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    if (!emailRegex.test(email))
      return
    if (!password?.length)
      return
    if (password !== confirm)
      return
    return true
  })

  const changeEmail = useCallback(e => {
    const val = e.target.value.trim()
    setIsValid(validate({email: val, password: password.value, confirm: confirmPass.value}))
    email.onChange(e)
  }, [validate, password, confirmPass])

  const changePass = useCallback(e => {
    const val = e.target.value.trim()
    setIsValid(validate({password: val, email: email.value, confirm: confirmPass.value}))
    password.onChange(e)
  }, [validate, email, confirmPass])

  const changeConfirmation = useCallback(e => {
    const val = e.target.value.trim()
    setIsValid(validate({confirm: val, password: password.value, email: email.value}))
    confirmPass.onChange(e)
  }, [validate, email, password])

  const handleSubmit = () => {
    registration(email.value, password.value).then(data => {
      if (!data.user)
        throw data
      user.login(data)
      window.notify({
        type: 'success',
        message: t('User created', { ns: 'main' })
      })
    }).catch(err => catchError(err, t))
  }

  const onKeyUp = useCallback(e => {
    if (e.keyCode === 13)
      handleSubmit()
  }, [email, password, confirmPass])

  return (
    <form className="d-flex flex-column">
      <Input {...email} onChange={changeEmail} onKeyUp={onKeyUp}
        placeholder={t('placeholderEmail', { ns: 'UI' })} className={`${(email.value === '') ? '' : 'filled'}`}/>
      <Input {...password} onChange={changePass} onKeyUp={onKeyUp}
        type="password" placeholder={t('placeholderPassword', { ns: 'UI' })} className={`${(password.value === '') ? '' : 'filled'}`} />
      <Input {...confirmPass} onChange={changeConfirmation} onKeyUp={onKeyUp}
        type="password" placeholder={t('placeholderConfirm', { ns: 'UI' })} className={`${(confirmPass.value === '') ? '' : 'filled'}`} />
      <Button onClick={handleSubmit} disabled={!isValid}>{t('registrationBtn', { ns: 'UI' })}</Button>
    </form>
  )
}

export default observer(Registration)
