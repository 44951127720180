import getLangBySubdomain from '../utils/getLangBySubdomain'
import setContacts from '../utils/setContacts'
import defaultConfig from '../default-config.json'

const contacts = setContacts();

const appSettings = {
  availableLang: defaultConfig?.availableLang,
  country: getLangBySubdomain(true),
  ...contacts
}

export default appSettings