import Tab from '../../UI/Tab/Tab'
import { useTranslation } from 'react-i18next'
import './TabsList.scss'


function TabsList({topics, useAll = true, currentTopic, onSelect, translate = false}) {
  const {t} = useTranslation()
  useAll && topics[0] !== 'all' && topics.unshift('all')

  function handleChooseTopic(e) {
    if (e.target.classList.contains('active')) return

    onSelect(e.target.getAttribute('data-topic'))
  }

  return (
    <div className='tabs row'>
      {
        topics?.map(topic => {
          return <Tab 
            key={topic}
            dataTopic={topic}
            selected={currentTopic === topic}
            onClick={e => handleChooseTopic(e)}
          >
            {translate ? t(topic, { ns: 'order' }) : topic}
          </Tab>
        })
      }
    </div>
  )
}

export default TabsList
