import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { ADD_SCENES_ROUTE, EDIT_SCENES_ROUTE, SCENES_ROUTE } from '../../utils/consts'
import { Link } from 'react-router-dom'
import { getScenes } from '../../API/sceneAPI'

function Scenes() {
  const [topic, setTopic] = useState('all')
  const [sceneList, setSceneList] = useState([])
  
  useEffect(() => {
    (async ()=>{
      const scenes = await getScenes()
      setSceneList(scenes)
    })()
  }, [])

  return (
    <LayoutAdmin currentPath={SCENES_ROUTE}>
      <h1 className='title'>Scenes ({sceneList.length})</h1>
      <div className='listScenes'>
      <Link to={ADD_SCENES_ROUTE} className='card adding'>+</Link>
      {
        sceneList.map(scene => {
          const src = process.env.REACT_APP_SCENES_PATH + `color/${scene.name}.svg`
          return <Link 
            key={scene.name}
            to={`${EDIT_SCENES_ROUTE}/${scene.name}`}
            className={`card ${scene.topic.includes(topic) ? 'd-none' : ''}`}
          >
            <span className='previewName'>{scene.name}</span>
            <img src={src} alt="" />
          </Link>
        })
      }
      </div>
    </LayoutAdmin>
  )
}

export default Scenes
