import React from 'react'
import './Card.scss'

function Card({selected, dataName, onClick, className='', controls, addScene, openScene, removeScene, disable, children}) {
  return (
    <div 
      className={`card ${className} ${selected ? 'selected' : ''} ${disable && !selected ? 'disable' : ''}`.trim()}
      data-name={dataName}
      onClick={onClick}
    >
      {children}
      {controls && <div className="controls">
        <a className="add" onClick={e => {addScene(e); e.stopPropagation()}}></a>
        <a className="edit" onClick={e => {openScene(e); e.stopPropagation()}}></a>
        <a className="remove" onClick={e => {removeScene(e); e.stopPropagation()}}></a>
      </div>}
      <div className='selectCurrent'></div>
    </div>
  )
}

export default Card
