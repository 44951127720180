import { $host } from "./index"

export const getCovers = async () => {
    const {data} = await $host.get('api/cover/')
    return data
}

export const saveCover = async (option) => {
    const {edit, ...cover} = option
    const {data} = edit ? await $host.put('api/cover/' + cover.name, cover) : await $host.post('api/cover/', cover)
    return data
}