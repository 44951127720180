import { observer } from "mobx-react-lite"
import { UserContext } from "../../utils/shared/Context"
import { useContext } from "react"
import Modal from "../../components/Modal/Modal"
import Authorization from "../../components/Authorization/Authorization"

const AuthLayout = ({children}) => {
  const {user} = useContext(UserContext)

  if(user.isAuth && user.user.role === 'admin') {
    return children
  }

  return <Modal active={true} setActive={() => console.log('Ooops!')}>
      <Authorization isAdminPart/>
    </Modal>
}

export default observer(AuthLayout)
