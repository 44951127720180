import {useContext} from 'react'
import {Navigate} from 'react-router-dom'
import {UserContext} from '../../utils/shared/Context'
import LayoutDefault from '../../modules/LayoutDefault'
import ProfileBlock from '../../modules/ProfileBlock/ProfileBlock'

const Profile = () => {
    const {user} = useContext(UserContext)
    if (!user.isAuth)
        return <Navigate to='/'/>

    return (
        <LayoutDefault>
            <ProfileBlock/>
        </LayoutDefault>
    )
}

export default Profile