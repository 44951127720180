import Multiselect from 'multiselect-react-dropdown'
import React, { useRef, useState } from 'react'
import Input from '../UI/Input/Input'
import defaultConfig from '../default-config.json'
import Select from '../UI/Select/Select'

function FacesDrawForm({editScene, setEditScene}) {
  const notDraw_man = useRef()
  const notDraw_woman = useRef()
  const [swapDrawPart, setSwapDrawPart] = useState({
    man: Object.keys(editScene?.settings?.draw_man?.swapDraw || {})[0],
    woman: Object.keys(editScene?.settings?.draw_woman?.swapDraw || {})[0]
  })
  const [swapDrawType, setSwapDrawType] = useState({
    man: Object.values(editScene?.settings?.draw_man?.swapDraw || {})[0],
    woman: Object.values(editScene?.settings?.draw_woman?.swapDraw || {})[0]
  })
  const listFacePart = {man: [], woman: []}
  Object.keys(defaultConfig.availableFacePart.color['man']).map(part => listFacePart['man'].push({value: part, name: part}))
  Object.keys(defaultConfig.availableFacePart.color['woman']).map(part => listFacePart['woman'].push({value: part, name: part}))
  
  function setNotDraw() {
    const newSettings = {...editScene.settings}
    if (notDraw_man.current) {
      const notDrawList = notDraw_man.current.getSelectedItems().map(v => v.value)
      newSettings.draw_man = {...editScene.settings.draw_man, "notDraw": notDrawList}
    }
    if (notDraw_woman.current) {
      const notDrawList = notDraw_woman.current.getSelectedItems().map(v => v.value)
      newSettings.draw_woman = {...editScene.settings.draw_woman, "notDraw": notDrawList}
    }
    setEditScene({...editScene, settings: {...newSettings}})
  }
  
  function setSwapDraw(e) {
    const val = e.target.value
    const sex = e.target.getAttribute('data-id')
    setSwapDrawPart(prev => ({...prev, [sex]: val === 'empty' ? null : val}))
  }

  function changeSwapDrawType(e) {
    const newSettings = {...editScene.settings}
    const val = e.target.value
    const sex = e.target.getAttribute('data-id')
    newSettings['draw_' + sex].swapDraw = val === 'empty' ? {} : {[swapDrawPart[sex]]: val}
    setEditScene({...editScene, settings: {...newSettings}})
    setSwapDrawType(prev => ({...prev, [sex]: val === 'empty' ? null : val}))
  }

  function handleSetValue(sex, name, pos = null, value) {
    const newSettings = {...editScene.settings}
    pos !== null ? newSettings[`draw_${sex}`][name][pos] = value : newSettings[`draw_${sex}`][name] = value
    setEditScene({...editScene, settings: {...newSettings}})
    console.log({...editScene, settings: {...newSettings}})
  }

  function handleSetSmile(sex, name, pos = null, value) {
    handleSetValue(sex, name, pos = null, value ? 'smile' : '')
  }

  return (
    <>
      {editScene?.settings && Object.keys(editScene?.settings).map((draw) => {
        const drawSet = editScene.settings[draw]
        const sex = draw.split('_')[1]
        const selectedNotDraw = listFacePart[sex].filter(notDraw => (drawSet.notDraw.includes(notDraw.name)))
        return <div key={sex} className='row'>
          <div className="label mt-15 col w-100">Draw settings for {sex}:</div>
          <div className={`col-10`}>
              <Input 
                  className={drawSet.angle || drawSet.angle === 0 ? 'filled' : ''}
                  value={drawSet.angle}
                  placeholder="Angle"
                  onChange={e => handleSetValue(sex, 'angle', null, e.target.value)}
              />
          </div>
          <div className={`col-10`}>
            <Input 
              className={drawSet.pos[0] || drawSet.pos[0] === 0 ? 'filled' : ''}
              value={drawSet.pos[0]}
              placeholder="Pos X" 
              onChange={e => handleSetValue(sex, 'pos', 0, e.target.value)}
            />
          </div>
          <div className={`col-10`}>
            <Input 
              className={drawSet.pos[1] || drawSet.pos[1] === 0 ? 'filled' : ''}
              value={drawSet.pos[1]}
              placeholder="Pos Y"
              onChange={e => handleSetValue(sex, 'pos', 1, e.target.value)}
            />
          </div>
          <div className={`col-10`}>
              <Input 
                  className={drawSet.width || drawSet.width === 0 ? 'filled' : ''}
                  value={drawSet.width}
                  placeholder="Width" 
                  onChange={e => handleSetValue(sex, 'width', null, e.target.value)}
              />
          </div>
          <div className={`col-10`}>
              <Input 
                  className={drawSet.height || drawSet.height === 0 ? 'filled' : ''}
                  value={drawSet.height}
                  placeholder="Height"
                  onChange={e => handleSetValue(sex, 'height', null, e.target.value)}
              />
          </div>
          <div className="col-25">
              <Input 
                  className='filled'
                  value={drawSet.eyeDirection}
                  placeholder="Eye direction [l,r,d,c,_]"
                  onChange={e => handleSetValue(sex, 'eyeDirection', null, e.target.value)}
              />
          </div>
          <div className="col-25">
              <Input 
                  className='filled'
                  value={drawSet.eyebrowsType}
                  placeholder="Eyebrows type [d,s,_]"
                  onChange={e => handleSetValue(sex, 'eyebrowsType', null, e.target.value)}
              />
          </div>
          <div className="col-33">
            <div className="label mt-15">What NOT needed to draw:</div>
            <Multiselect
              id={`not_draw_`+sex}
              ref={sex === 'man' ? notDraw_man : notDraw_woman}
              options={listFacePart[sex]}
              selectedValues={selectedNotDraw}
              onSelect={setNotDraw}
              onRemove={setNotDraw}
              displayValue="name"
            />
          </div>
          <div className="col-15 mt-25">
              <label htmlFor={`smile_`+sex} className='checkBox mt-25'>
                  <input id={`smile_`+sex} type='checkbox'
                      checked={!!drawSet.lipsType}
                      onChange={e => handleSetSmile(sex, 'lipsType', null, e.target.checked)}/>
                  <div className='box'></div>
                  <span className='px-15'>Use smile</span>
              </label>
          </div>
          <div className="col-25">
            <div className="label mt-15">Swap face part:</div>
            <Select
              dataId={sex}
              options={Object.keys(defaultConfig.swapParts[sex] || {})}
              value={swapDrawPart[sex]}
              onChange={setSwapDraw}
            >
              <option value="empty">-</option>
            </Select>
          </div>
          {swapDrawPart[sex] && <div className="col-25">
            <div className="label mt-15">Type of swapped part:</div>
            <Select
              dataId={sex}
              options={defaultConfig.swapParts[sex][swapDrawPart[sex]] || []}
              value={swapDrawType[sex]}
              onChange={changeSwapDrawType}>
                <option value="empty">-</option>
            </Select>
          </div>}
        </div>
      })}
    </>
  )
}

export default FacesDrawForm
