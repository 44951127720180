import storageOrder from "../store/storageProvider"

export  function checkOrderStorage() {
  return {
    step: storageOrder.getItem('currentStep'),
    typeComic: storageOrder.getItem('typeComic'),
    selectedTheme: storageOrder.getItem('selectedTheme'),
    hasCouple: storageOrder.getItem('hasCouple'),
    selectedSkin: storageOrder.getItem('selectedSkin'),
    selectedFaceParts: storageOrder.getItem('selectedFaceParts'),
    selectedFacePartsColor: storageOrder.getItem('selectedFacePartsColor'),
    lipsPosition: storageOrder.getItem('lipsPosition'),
    selectedCover: storageOrder.getItem('selectedCover'),
    selectedScenes: storageOrder.getItem('selectedScenes'),
    sequenceScenes: storageOrder.getItem('sequenceScenes'),
  }
}

