import defaultConfig from '../default-config.json'
import getLangBySubdomain from './getLangBySubdomain'

function setContacts() {
  const lang = getLangBySubdomain();
  if (!defaultConfig.availableContacts.lang) return {
    ...defaultConfig.availableContacts['ua']
  }
  
  return {
    ...defaultConfig.availableContacts[lang]
  }
}

export default setContacts
