import React from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { VOUCHERS_ROUTE } from '../../utils/consts'
import { useNavigate, useParams } from 'react-router-dom'
import VoucherCreator from '../../widgets/VoucherCreator/VoucherCreator'

function VoucherOne() {
  const {code} = useParams()
  const navigate = useNavigate()
  const goBack = () => navigate('/admin/vouchers/')

  return (
    <LayoutAdmin currentPath={VOUCHERS_ROUTE}>
      <h1 className='title'>
        <div style={{cursor: 'pointer'}} onClick={goBack}>{'<'}&nbsp;</div>
        {code ? `Edit ${code}` : 'Add voucher'}
      </h1>
      <VoucherCreator code={code}/>
    </LayoutAdmin>
  )
}

export default VoucherOne
