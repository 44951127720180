import { useTranslation } from 'react-i18next'
import LayoutDefault from '../../modules/LayoutDefault'
import appSettings from '../../store/appSettings'

const Warranty = () => {
  const { t } = useTranslation()

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <h1 className="h3">{t('warrantyMenu', { ns: 'main' })}</h1>
            <p className="mt-15">
              {t('For books', { ns: 'main' })}
              <br/><br/>
              {t('Warranty extends', { ns: 'main' })}
              <br/><br/>
              {t('TTN', { ns: 'main' })}
              <br/><br/><br/>
              {t('Import', { ns: 'main' })}!
              <br/><br/>
              {t('Check the goods', { ns: 'main' })}
              <br/><br/>
              {t('Leave post', { ns: 'main' })}
              <br/><br/>
              {t('If you have problems', { ns: 'main' })}: {appSettings.phone},
              {t('No leave post', { ns: 'main' })}
            </p>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Warranty