export const themeDepends = {
  'husband': ['man','man'],
  'father':  ['him','man'],
  'wife':    ['woman','woman'],
  'mother':  ['her','woman'],
  'friends': ['', 'man'],
};

export default function getThemeId(person, couple) {
  const status = couple ? 'couple' : 'only'
  const who = couple ? themeDepends[person][0] : themeDepends[person][1]

  return person === 'friends' ? status : `${status}_${who}`
}
