import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SCENES_ROUTE } from '../../utils/consts'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import SceneCreator from '../../widgets/SceneCreator/SceneCreator'

function Scene() {
  const {id} = useParams()
  const navigate = useNavigate()
  const goBack = () => navigate('/admin/scenes/')

  return (
    <LayoutAdmin currentPath={SCENES_ROUTE}>
      <h1 className='title'>
        <div style={{cursor: 'pointer'}} onClick={goBack}>{'<'}&nbsp;</div>
        {id ? `Edit ${id}` : 'Add scene'}
      </h1>
      <SceneCreator id={id}/>
    </LayoutAdmin>
  )
}

export default Scene
