import { useTranslation } from 'react-i18next'
import LayoutDefault from '../../modules/LayoutDefault'
import SocialChanel from '../../components/SocialChanel/SocialChanel'

const Contacts = () => {
  const { t } = useTranslation()

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <h1 className="h3">{t('Contacts title', { ns: 'main' })}</h1>
            <div className="row mt-15">
              <div className="col-50">
                <p>{t('Contacts info', { ns: 'main' })}</p>
                <SocialChanel/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Contacts