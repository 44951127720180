import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from './../../UI/Select/Select'
import defaultConfig from '../../../src/default-config.json'
import Multiselect from 'multiselect-react-dropdown'
import Input from './../../UI/Input/Input'
import { getLastScene, getScene, saveScene } from '../../API/sceneAPI'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { OrderContext } from '../../utils/shared/Context'
import { padNum } from './../../utils/shared/padNumber'
import getLangBySubdomain from '../../utils/getLangBySubdomain'
import Tabs from '../../components/Tabs/Tabs'
import OtherTextRow from '../../components/OtherTextRow'
import Card from './../../components/Card/Card'
import Scene from '../../components/Scene'
import FacesDrawForm from '../../components/FacesDrawForm'
import Button from './../../UI/Button/Button'
import { sceneBlank } from './../../utils/consts'
import './SceneCreator.scss'

const defaultDrawSettings = {
    "pos": [0,0],
    "width": 383,
    "height": 536,
    "angle": 0,
    "notDraw": [],
    "swapDraw": [],
    "eyebrowsType": "",
    "eyeDirection": "",
    "lipsType": "",
}

function SceneCreator({id}) {
    const {order} = useContext(OrderContext)
    const [editScene, setEditScene] = useState()
    const [onlyShape, toggleOnlyShape] = useState(false)
    const [zoomIn, setZoomIn] = useState(false)
    const [curLang, setCurLang] = useState(getLangBySubdomain(true))
    const optionsScene = [
        {value: 'man', name: 'man'},
        {value: 'woman', name: 'woman'},
        {value: 'couple', name: 'couple'}]
    const multiselectTopics = useRef()
    const topics = defaultConfig.topicsScene.reduce((a, v, i) => {a.push({name: v, id: i}); return a}, [])
    const selectedTopics = (editScene) ? topics.filter(topic => (editScene.topic.includes(topic.name))) : []
    const mainText = useRef()
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => order.autoSavingSelectedParts(), 200)
    }, [])

    function handleMainText(type, lang, value) {
        const newScene = {...editScene}
        if (!newScene.mainText[type]) newScene.mainText[type] = {}
        newScene.mainText[type][lang] = value
        setEditScene({...newScene})
        mainText.current.innerText = value
    }

    function setTopics() {
        setEditScene({...editScene, topic: multiselectTopics.current.getSelectedItems().map(v => v.name)})
    }

    function setTypeScene(val) {
        const settings = {}
        switch (val) {
            case 'man': settings['draw_man'] = {...defaultDrawSettings}; break
            case 'woman': settings['draw_woman'] = {...defaultDrawSettings}; break
            case 'couple': 
            settings['draw_man'] = {...defaultDrawSettings}; 
            settings['draw_woman'] = {...defaultDrawSettings, pos: [400,0]}; break
        }
        setEditScene({...editScene, settings: settings, type: val})
    }

    function addOtherTextRow() {
        const newScene = {...editScene} 
        newScene.otherText[newScene.otherText.length] = {
            'text': {},
            'pos': [0,0],
            'size': [0,0],
        }
        setEditScene({...newScene})
    }

    useEffect(() => {
        setTimeout(() => {
            id ? getScene(id).then(sceneData => {
                console.log(sceneData)
                setEditScene({...sceneData, edit: true})
            }) : getLastScene().then(lastSceneData => {
                const newNameScene = (lastSceneData[0]) ? 'Scene_' + padNum(Number(lastSceneData[0].name.split('_')[1]) + 1) : 'Scene_001'
                setEditScene({...sceneBlank, name: newNameScene})
            })
        }, 400)
    }, [])

    async function save(e) {
        e.target.classList.add('disabled')
        saveScene(editScene).then(res => {
            navigate('/admin/scenes')
        }).catch(err => {
            err?.response && console.error(err?.response.data.message)
            e.target.classList.remove('disabled')
        })
    }

    if (!editScene) {
        return <></>
    }

    return (<div className='sceneCreatorWrap'>
    <form className='sceneCreator' data-action={id ? 'edit' : 'add'} action="#">
        <div className="row">
            <div className="col-50">
                <div className="label">Choose type of scene:</div>
                <Select options={optionsScene} value={editScene?.type} onChange={(e) => {setTypeScene(e.target.value)}}>
                    <option value="empty">Empty</option>
                </Select>
            </div>
            <div className="col-50">
                <div className="label">Choose topics of scene:</div>
                <Multiselect
                    id="topics"
                    ref={multiselectTopics}
                    options={topics}
                    selectedValues={selectedTopics}
                    onSelect={setTopics}
                    onRemove={setTopics}
                    displayValue="name"
                />
            </div>
        </div>
        <FacesDrawForm editScene={editScene} setEditScene={setEditScene}/>
        <div className="label w-100">Input main text of scene:</div>
        <Tabs 
            topics={defaultConfig.availableLang}
            currentTopic={curLang}
            onSelect={setCurLang}
            className='mb-10'
        >
            <div className='row'>
                {editScene?.name !== 'Scene_000' && Object.keys(sceneBlank?.mainText).map((type) => {
                    if ((editScene.type === 'couple') && type.startsWith('only')) return
                    if ((editScene.type === 'man') && type === 'only_woman') return
                    if ((editScene.type === 'woman') && type === 'only_man') return
                    return <div key={type} className='col-50'>
                        {defaultConfig.availableLang.map((lang) => {
                            return <div key={lang} className={`mb-10 ${curLang !== lang ? 'd-none' : ''}`}>
                                <Input 
                                    className={editScene.mainText[type]?.[lang] ? 'filled' : ''}
                                    value={editScene.mainText[type]?.[lang] || ''}
                                    placeholder={type} 
                                    onChange={e => handleMainText(type, lang, e.target.value)}
                                />
                            </div>
                        })}
                    </div>
                })}
            </div>
            <div className="label mt-15 w-100">
                Input other text of scene if needed:
                <span onClick={addOtherTextRow} className='plus-around'>+</span>
            </div>
            <OtherTextRow 
                editScene={editScene}
                setEditScene={setEditScene}
                curLang={curLang}
            />
        </Tabs>
        <Input 
            className="filled"
            value={editScene.popular || 0}
            placeholder="Popular"
            onChange={e => setEditScene(prev => ({...prev, popular: e.target.value}))}
        />
    </form>
    <div className='previewScene'>
        <Button className='mb-15' onClick={() => toggleOnlyShape(!onlyShape)}>
            Toggle face
        </Button>
        <Button className='mb-15' onClick={() => setEditScene({...editScene, color: editScene.color !== 'color' ? 'color' : 'monochrome'})}>
            Toggle color
        </Button>
        {editScene?.name !== 'Scene_000' && <>
            <div className={`zoomInScene ${zoomIn && 'active'}`} onClick={() => setZoomIn(false)}>
                <Scene 
                    sceneName={editScene.name.toLocaleLowerCase()}
                    scene={editScene}
                    width={800}
                    height={960}
                    pos={[0,0]}
                    onlyShape={onlyShape}
                />
            </div>
            <Card dataName={editScene.name.toLocaleLowerCase()} onClick={() => setZoomIn(true)}>
                <p ref={mainText} className="mainText">
                    {editScene?.mainText.couple_man[curLang] || "input some text"}
                </p>
                <div className="sceneBox">
                {editScene.otherText?.map((context ,key) => {
                    return <p 
                    key={`${id}_${key}`}
                    id={`otherText_${key}`}
                    className="otherText"
                    style={{
                        width: context.size[0],
                        height: context.size[1],
                        left: context.pos[0],
                        top: context.pos[1]
                    }}
                    >
                    {context.text[curLang]}
                    </p>
                })}
                <Scene 
                    sceneName={editScene.name.toLocaleLowerCase()}
                    scene={editScene}
                    width={400}
                    height={480}
                    pos={[0,0]}
                    onlyShape={onlyShape}
                />
                </div>
            </Card>
        </>}
        <Button className='mt-15' onClick={save}>Save</Button>
        <div id='testMainText'></div>
        <div id='testOtherText'></div>
    </div>
  </div>)
}

export default observer(SceneCreator)
