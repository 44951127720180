export function catchError(err, t) {
    const error = err?.response?.data || err
    if (!error?.errors?.length) {
        window.notify({
            type: 'error',
            message: t(error.message || "Something wrong", {ns: 'errors'})
        })
    } else {
        error?.errors?.forEach(e => {
            window.notify({
                type: 'error',
                message: t(e.msg || "Something wrong", {ns: 'errors'})
            })
        })
    }
}