import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { ADD_VOUCHERS_ROUTE, EDIT_VOUCHERS_ROUTE, VOUCHERS_ROUTE } from '../../utils/consts'
import { Link } from 'react-router-dom'
import { getVouchers } from '../../API/voucherAPI'

function Vouchers() {
  const [voucherList, setVoucherList] = useState([])
  const columnsTable = ['code', 'value', 'usedAmount']
  
  useEffect(() => {
    (async ()=>{
      const vouchers = await getVouchers()
      setVoucherList(vouchers)
    })()
  }, [])

  return (
    <LayoutAdmin currentPath={VOUCHERS_ROUTE}>
      <h1 className='title'>Vouchers ({voucherList.length}) <Link to={ADD_VOUCHERS_ROUTE}>ADD MORE</Link></h1>
      <table className='listVouchers'>
        <thead><tr>{columnsTable.map(td => <th key={td}>{td}</th>)}</tr></thead>
        <tbody>{voucherList.map(voucher => {
          return <tr key={voucher._id}>
              <td>{voucher.code}</td>
              <td>{voucher.value}%</td>
              <td>{voucher.usedAmount ? voucher.usedAmount : 0}</td>
              <td>
                <Link to={`${EDIT_VOUCHERS_ROUTE}/${voucher.code}`}>Edit</Link>
              </td>
            </tr>
          })
        }</tbody>
      </table>
    </LayoutAdmin>
  )
}

export default Vouchers
