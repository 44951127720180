import React from 'react'
import { useTranslation } from 'react-i18next'
import appSettings from '../../store/appSettings'
import Button from '../../UI/Button/Button'
import './Footer.scss'

const unformattedPhone = (phone) => {
  return phone.replace(/[() -]/g,'')
}

const Footer = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  return (
    <footer className='footer'>
      <div className='innerWidth paddingPage'>
        <div className="footerLine">
          <a href='/' className='logo'>
            <img src="/assets/images/logo.svg" alt="LoveInBook.com" />
            LoveInBook
          </a>
          <a href={`mailto:${appSettings.email}`}>{appSettings.email}</a>
          <a href={`tel:${unformattedPhone(appSettings.phone)}`}>{appSettings.phone}</a>
          <div className="social">
            <a href={appSettings.insta} className='icon-instagram' title='LoveInBook instagram'/>
            <a href={appSettings.facebook} className='icon-facebook' title='LoveInBook facebook'/>
            <a href={appSettings.telegram} className='icon-telegram' title='LoveInBook telegram'/>
          </div>
        </div>
        <div className="hr"/>
        <div className="footerLine">
          <p>© {year} LoveInBook.com</p>
          <ul className="footerMenu">
            <li><a href="/privacy-policy">{t('policyMenu', { ns: 'main' })}</a></li>
            <li><a href="/warranty">{t('warrantyMenu', { ns: 'main' })}</a></li>
          </ul>
          <Button className="tryNow" href="/order">{t('letsTryBtn', { ns: 'UI' })}</Button>
        </div>
      </div>
    </footer>
  )
}

export default Footer
