import React from 'react'
import TabsList from '../TabsList/TabsList'
import './Tabs.scss'

function Tabs({topics, currentTopic, onSelect, className, children}) {
  return (
    <div className={`tabs-container ${className}`}>
      <TabsList topics={topics} useAll={false} currentTopic={currentTopic} onSelect={onSelect}/>
      <div className='tabs-body'>
        {children}
      </div>
    </div>
  )
}

export default Tabs
